.imgBox {
  display: flex;
  flex-direction: column;
  box-shadow: $shadow-s;
  border-radius: 6px;
  padding: 20px;
  margin: 50px 0 10px 0;
}

.img {
  display: block;
  width: -webkit-fill-available;

}

.vector {
  padding: 0 15px 0 0
}

.vector-arrow{
  padding: 15px
}

