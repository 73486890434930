.textBox {
  max-width: 567px;
  width: auto;
  display: grid;
  margin: 0 auto;
  padding: 106px 20px 27px;
}

.title {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;

  color: #343a40;
}

.ligthTitle {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height */

  color: #ffffff;
}

.content {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #6c757d;
}

.ligthContent {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #ffffff;
}
