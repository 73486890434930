body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /*   max-width: 576px; */
  width: 100%;
  margin: 0 auto;
  overflow-y: auto !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  margin: 20px;
}

a {
  text-decoration: none;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
}

b {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;

  color: #343a40;
}

p {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

sup {
  vertical-align: top;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0 3% 0 5%;
  color: #212529;
  text-decoration: none;
  /* background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);
      border-top-color: rgba(0, 0, 0, 0.125);
      border-right-color: rgba(0, 0, 0, 0.125);
      border-bottom-color: rgba(0, 0, 0, 0.125);
      border-left-color: rgba(0, 0, 0, 0.125); */

  background: #ffffff;
  box-shadow: 0px 7px 11px rgba(52, 58, 64, 0.3);
  border-radius: 6px;
  margin: 2% 0 4% 0;
  display: flex;
  align-items: center;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;

  color: #343a40;
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item.active {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;

  color: #b16db3;
  background: #ead7ea;

  /* error vector shadow */
  box-shadow: 0px 7px 11px rgba(52, 58, 64, 0.3);
  border-radius: 6px;
  display: flex;
  align-items: center;
}
.list-group-item.maintenance {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;

  color: #ffffff;
  background: #cda1ce;
  /* error vector shadow */
  box-shadow: 0px 7px 11px rgba(52, 58, 64, 0.3);
  border-radius: 6px;
}

.list-group-item img {
  cursor: pointer;
}

.instillationName {
  background-color: transparent;
}

.list-group-item > material-icons.executed {
  color: #b16db3;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #a4aeb6;
  cursor: default;
  pointer-events: none;
  background: #dde1e4;
}

.icon {
  margin: 0 5% 0 0;
}

.instillationName {
  background-color: transparent;
  font-weight: bold;
}

.instillationCalendar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
}

.instillationCalendar2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  background: #ead7ea;
  border-radius: 8px;
}

.instillationTable {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  margin: 0 0 0 9px;
  height: 38px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
}

video {
  height: max-content !important;
  /* width: max-content !important; */
}

.react-player {
  height: max-content !important;
  /* width: max-content !important; */
}

.surveyDetails {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  color: #b16db3;
}

/* CONTAINER */

.H-Center-Container {
  display: flex;
  justify-content: center;
}

.V-Center-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* SELECT COUNTRY STYLES */

.flag-item {
  width: 350px;
  height: 220px;
  border-radius: 20px;
}

.SC-scroll-container {
  overflow-y: auto;
  padding: 20px;
}

.SC-scroll-item {
  border-radius: 20px;
  padding: 10px;
  margin: 7px;
  transition: background-color 0.5s ease, opacity 0.5s ease; /* Agrupamos las propiedades de transición */
  cursor: pointer;
  opacity: 0.5;
}

.SC-scroll-item:hover {
  background-color: #e58de8;
  opacity: 1;
}

.country-indicator {
  width: 35px;
}

.country-name {
  color: #b16db3;
  margin: 0;
}

/* SWITCH COUNTRY STYLES */

.country-switch {
  display: flex;
  align-items: center;
}
