.circularProgress{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.progressContainer{
    height: 100px;
    display: flex;
    align-items: center;

}

.porcentage{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
    line-height: 28px;
}

.progressContainerOFF{
    width: 100px;
    height: 100px;
    background: #EAD7EA;
    border-radius: 50%;
    margin: 0 0 0 -100px;
}

.insideCircle{
    width: 82px;
    height: 82px;
    background: #ffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 -91px;
}

.completed{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-top: 5%;

    text-align: center;

    color: #6C757D;
}

.off{
    width: 100px !important;
    height: 100px !important;
}
