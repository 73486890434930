.innerContainer {
  max-width: 576px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  box-sizing: border-box;
}

.boxContainer {
  background: #f4f5f6;
  padding: 64px 0 114px 0;
  background: #dbe4ed;
}

.title {
  margin: 0 104px 0 16px;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;

  color: #343a40;
}

.img {
  width: 100%;
  height: 100%;
  margin: 5% 4% 8% 4%;

  border-radius: 12px;
}
