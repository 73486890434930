/* ---------------------PURPLE--------------------- */
.bold-purple {
  font-weight: bold;
  color: map_get($primary, "purple");
}

.bold-purple2 {
  font-weight: bold;
  color: map_get($primary, "purple");
  padding: 0 0 5px 10px;
  margin: 0;
}

.title-purple {
  font-weight: 900;
  font-size: 18px;
  line-height: 23px;
  padding: 14px;
  margin: 0;

  color: map_get($primary, "purple");
}

.subtitle-purple {
  font-weight: 900;
  color: map_get($primary, "purple-3");
}

.subtitle-purple-2 {
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  margin: -10px 0 20px 0;

  color: map_get($primary, "purple");
}

.bibliography-purple {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;

  color: map_get($primary, "purple");
}

.error-title-1 {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 900;
  font-size: 60px;
  line-height: 75px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0;
  color: #894381;

  text-shadow: 0px 2px 4px rgba(52, 58, 64, 0.4);
}

.error-title-2 {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 900;
  font-size: 120px;
  line-height: 151px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0;
  color: #894381;

  text-shadow: 0px 2px 4px rgba(52, 58, 64, 0.4);
}

/* ---------------------WHITE--------------------- */
.banner-1 {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 14px;
  margin: 12.5px 0 13.5px 0;

  color: map_get($primary, "white");
}

.header-1-title {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  margin: 0 0 15px 0;

  color: map_get($primary, "white");
}

.header-1-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin: 0 20px 10px 0;

  color: map_get($primary, "white");
}

.header-2-greetings {
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 0 8px;

  color: map_get($primary, "white");
}

.header-2-name {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  margin: 0 0 15px 0;

  color: map_get($primary, "white");
}

.header-2-bread-title {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  max-width: 576px;
  margin: 0 auto;
  padding: 30px 0 30px 16px;


  color: #894381;
}

.header-register {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 12.5px;
  line-height: 14px;

  text-align: right;

  color: map_get($primary, "white");
  margin: 0 10px 0 0;
}

.header-subtitle {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin: 0 16px 0 0;

  color: map_get($primary, "white");
}

.white-title {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;

  color: map_get($primary, "white");
}

.white-title-2 {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: map_get($primary, "white");
}

.white-font-1 {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: map_get($primary, "white");
}

.white-font-underline {
  font-family: Source Sans Pro;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;

  color: map_get($primary, "white");
}

.white-font-2 {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;

  color: map_get($primary, "white");
}

/* ---------------------DARKEN--------------------- */

.italics-gray {
  font-style: italic;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  padding: 0 0 36px 0;

  color: map_get($gray, "darken-2");
}

.file-title {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  margin: 0 0 0 0;

  color: #6c757d;
}

.file-name {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin: 0;

  color: map_get($gray, "darken-1");
}

.form-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin: 30px auto 15px auto;

  color: map_get($gray, "darken-1");
}

.form-title-2 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin: 30px auto 15px auto;

  color: map_get($gray, "darken-2");
}

.title-1 {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  margin: 30px 0 20px 0;

  color: map_get($gray, "darken-1");
}

.title-2 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: map_get($gray, "darken-1");
}

.title-gray {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  margin: 30px 0 20px 0;

  color: map_get($gray, "darken-2");
}

.title-gray-2 {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0;

  color: map_get($gray, "darken-2");
}

.accordion-title-2 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: map_get($gray, "darken-1");
}

.bibliography-font {
  font-size: 14px;
  line-height: 18px;
}

.accordion-title {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.instillation-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.instillation-buttons-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 560px) {
  .instillation-buttons {
    flex-direction: column;
  }
}