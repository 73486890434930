.header-wm-container {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
}

.header-wm-container2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 14px;
  position: relative;
  z-index: 10;
  /*  box-shadow: 0px 2px 4px 0px #343a4066; */
}

.header-wm-logo {
  max-width: 102px;
  width: 100%;
}

.hwm-button-menu {
  cursor: pointer;
  display: block;
  max-width: 16px;
  width: 100%;

  &.menu-open {
    span.first {
      top: 4px;
      transform: rotate(-45deg);
      transition: 0.32s;
    }

    span.last {
      bottom: 4px;
      transform: rotate(45deg);
      transition: 0.4s;
    }
  }
}

.hwm-iconLine {
  display: block;
  width: 100%;
  height: 2px;
  border-radius: 32px;
  background-color: #ffffff;
  position: relative;
  transition: 0.3s;

  &.first {
    margin-bottom: 6px;
  }
}

.hwm-menu-container {
  display: block;
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  transform: translateX(200vw);
  transition: 0.3s;

  &.menu-open {
    background: #343a4059;
    transform: translateX(0);
    transition: 0.3s;
  }
}

.hwm-menu {
  overflow: auto;
  display: block;
  max-width: 290px;
  width: 100%;
  padding: 50px 0;
  position: fixed;
  top: 20px;
  right: 0;
  bottom: 0;
  margin: 0;
  list-style: none;
  background: #ffffff;
  box-sizing: border-box;
}

.hwm-menu-item {
  a {
    display: block;
    width: 100%;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #343a40;
    text-decoration: none;
    padding: 11px 30px;
    box-sizing: border-box;
  }
}

.menu-separator {
  max-width: 230px;
  width: 100%;
  margin: 5px auto;
  background: #b16db3;
  border: 1px solid #b16db3;
}
