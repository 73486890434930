.file {
  display: flex;
  flex-direction: column;
  margin: 20px auto 20px auto;
  max-width: 360px;
  padding: 20px;
  box-shadow: $shadow-m;
  border-radius: $border-radius;
  
  .file2Obj{
    display: flex;
    align-items: center;
    padding: 10px 0 10px 0;
    justify-content: space-around;

    .fileobj{
      display: flex;
      align-items: center;
      padding: 10px 0 10px 0;
      width: 179px;
      .icon-background-B {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #d7f8fe;
        width: 36px;
        min-width: 36px;
        height: 36px;
        border-radius: 4px;
      }
      .file-info {
        padding: 0 0 0 10px;
      }
    }

  }
  .file-segment {
    display: flex;
    align-items: center;
    padding: 10px 0 10px 0;

    .icon-background-A {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffe8d6;
      width: 36px;
      height: 36px;
      border-radius: 4px;
    }
    .icon-background-B {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #d7f8fe;
      width: 36px;
      height: 36px;
      border-radius: 4px;
    }
    .icon-background-C {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #e0f5ee;
      width: 36px;
      height: 36px;
      border-radius: 4px;
    }
    .icon-background-D {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f5e0f5;
      width: 36px;
      height: 36px;
      border-radius: 4px;
    }

    .file-info {
      padding: 0 0 0 10px;
    }
  }
  .file-segment-a {
    display: flex;
    align-items: center;
    padding: 10px 0px 10px 32px;

    .icon-background-A {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffe8d6;
      width: 36px;
      height: 36px;
      border-radius: 4px;
    }
    .icon-background-B {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #d7f8fe;
      width: 36px;
      height: 36px;
      border-radius: 4px;
    }
    .icon-background-C {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #e0f5ee;
      width: 36px;
      height: 36px;
      border-radius: 4px;
    }
    .icon-background-D {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f5e0f5;
      width: 36px;
      height: 36px;
      border-radius: 4px;
    }

    .file-info {
      padding: 0 0 0 10px;
    }
  }

  .a-center {
    align-self: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-decoration-line: underline;

    color: map_get($primary, "purple");
  }

  .a-end {
    align-self: flex-end;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
    text-decoration-line: underline;

    color: map_get($primary, "purple");
  }
  
}

@media only screen and (max-width: 400px) {
  .file2Obj{ 
    display: contents !important;
  }
}

.a-end-b {
  align-self: flex-end;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  text-decoration-line: underline;
  color: #ae5ca4;
  margin: -28px 126px 17px 0;
}