.footer-box {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  bottom: 0;
  width: 100%;
  padding: 20px;

  background: #FAFAFA;
  box-sizing: border-box;

  img {
    margin: 5px 0 15px 0;
    width: 200px;
  }
}

.footer-text{
  font-size: 12px;
  line-height: 15px;
  
  color: #AE5CA4;
}


.footer-text-2{
  font-size: 14px;
  font-size: 14px;
  line-height: 18px;
  color: #AE5CA4;
}


.link {
  font-size: 14px;
  line-height: 18px;

  text-align: center;
  text-decoration-line: underline;

  color: map_get($primary,'purple-2');
  margin: 6.5px;

  cursor: pointer;
}
