.button {
    width: 100%;
    background: #b16db3;

    margin: 20% 0 15% 0px;

    box-shadow: 0px 7px 11px rgba(132, 69, 135, 0.3);
    border-radius: 6px;
    border: none;
    align-self: center;
}

.nameContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 70%;
}

.buttonContent {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    color: #ffffff;
}
.buttonContainer {
    display: flex;
}

.buttonContent {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 11.5px;

    color: #ffffff;
}

.confirmButtonA {
    width: 110px;
    background: #ffffff;
    margin: 5% 10px 15% 10px;
    border: 2px solid #b16db3;
    box-sizing: border-box;

    box-shadow: 0px 7px 11px rgba(132, 69, 135, 0.3);
    border-radius: 6px;
}

.buttonContentA {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 11.5px;

    color: #b16db3;
}

.confirmButtonB {
    width: 120px;
    background: #b16db3;

    margin: 5% 10px 15% 10px;

    box-shadow: 0px 7px 11px rgba(132, 69, 135, 0.3);
    border-radius: 6px;
    border: none;
    align-self: center;
}

.buttonContentB {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    margin: 11.5px;
    font-size: 16px;
    line-height: 20px;

    color: #ffffff;
}


