.headerBox{
    display: grid;
    width: 100%;
    background: #B16DB3;
}

.innerContainer {
    max-width: 576px;
    width: 100%;
    margin: 0 auto;
}

.logo{
    max-width: 50%;
    width: 100%;
    display: block;
    margin: 10% auto 10%;
}