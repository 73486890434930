.card-container {
  display: flex;
  flex-direction: column;
  margin: 0 20px 25px;
}

.boxImg {
  width: 100%;
  max-width: 360px;
  height: 200px;
  margin: 0 auto;
  cursor: pointer;

  filter: drop-shadow(0px 7px 11px rgba(52, 58, 64, 0.3));
  border-radius: 6px 6px 0px 0px;
}

.card-container-title {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  z-index: 1;
  max-width: 360px;
  margin: 0 auto;

  background: #ffffff;

  box-shadow: 0px 7px 11px rgba(52, 58, 64, 0.3);
  border-radius: 0px 0px 6px 6px;
  cursor: pointer;
}

.title {
  margin: 20px 0 20px 13px;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  color: #343a40;
  cursor: pointer;
}

.card-icon {
  margin: 0 0 0 24px;
}

/* ------Education Card------- */

.educationBoxTitle {
  background-color: #834586;
  padding: 25px 20px 16px 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.educationBox1 {
  display: flex;
  align-items: center;
  background-color: #b06db4;
  padding: 25px 20px 16px 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.educationBox2 {
  display: flex;
  align-items: center;
  background-color: #b06db4;
  padding: 25px 20px 16px 20px;
}

.educationBox3 {
  display: flex;
  align-items: center;
  background-color: #b06db4;
  padding: 25px 20px 16px 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.educationCard1 {
  display: flex;
  flex-direction: column;
}

.number {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 65px;
  line-height: 0;
  color: #ffffff;
  margin: 0 10px 0 10px;
}

.whiteTextTitle {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 0;
  color: #ffffff;
  margin: 40px 0 25px 0;
}

.whiteText {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;

  color: #ffffff;
}

.whiteBold {
  color: #ffffff;
}

.colorText {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;

  color: #ffffff;
}

.imgContainer {
  background-color: #f5ebf4;
  display: flex;
  flex-direction: column;
  padding: 50px 0 50px 0;
}

.imgEducation {
  width: 260px;
  border-radius: 20px;
  margin: 10px auto 10px;
}

.imgBox1 {
  display: flex;
  background-color: #f5ebf5;
  padding: 25px 50px 25px 50px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.imgBox2 {
  display: flex;
}

.imgBox3 {
  display: flex;
  background-color: #f5ebf5;
}

.imgEducation2 {
  margin: 0 auto;
}
.imgEducation3 {
  margin: 0 auto;
}

/* ------Recommendation Card------- */

.recommendationBox1 {
  background-color: #f5ebf4;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.recommendationBox2 {
  background-color: #b06db4;
}

.recommendationBox3 {
  background-color: #f5ebf4;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.recommendationBox4 {
  background-color: #b06db4;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.recommendationBox5 {
  background-color: #f5ebf4;
}

.recommendationImgBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 55px 25px 35px 0;
}

.recommendationImgBox2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 55px 0 20px 25px;
}

.numberBox {
  background-color: #b06db4;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  height: 70px;
}

.numberBox2 {
  display: flex;
  background-color: #ffffff;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  height: 70px;
}

.number {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 65px;
  color: #ffffff;
  margin: 25px 25px;
}

.number2 {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 65px;
  color: #b06db4;
  margin: 25px 25px;
}

.whiteText {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  padding: 0 16px 25px 16px;
  color: #ffffff;
  margin: 0;
}

.colorText {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #353c42;
  padding: 0 16px 25px 16px;
  margin: 0;
}

.imgRecomendation1 {
  width: 200px;
}

.list {
  display: flex;
}

.b {
  font-size: 16px;
}

/* ------Caution Card------- */

.caution-card {
  display: flex;
  align-items: center;
  width: auto;

  margin: 0 16px 64px 16px;

  background: #f9dcdc;
  border: 1px solid #d62424;
  border-radius: 6px;
}

.caution-img {
  margin: 0 3% 0 3%;
}

.caution-text {
  margin: 17px 12px 17px 0;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;

  color: #d62424;
}

.videoPlayer {
  width: 730px;
  margin: 0 auto 0 auto;
}

.modal-pdf {
  border-radius: 6px;
}

.pdf-container {
  height: 85%;
  width: 100%;
}

.button-pdf-container {
  display: flex;
  justify-content: center;
}
