.ModalContainerPWA {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 14px 20px;
  background-color: #fff;
  position: fixed;
  bottom: 0vh;
  box-shadow: 0px -1px 7px rgba(52, 58, 64, 0.15);
  box-sizing: border-box;
  transform: translateX(-150%); 
  transition: all 200ms ease-in-out;

  &.show {
    transform: translateX(0%);
    transition: all 200ms ease-in-out;
  }
}

.InnerModalContainerPWA {
  max-width: 240px;
  width: 100%;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.logoPWA {
  max-width: 35px;
  width: 100%;
  margin: 0 12px 0 0;
}

.DownloadPWA {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #007af5;
  text-decoration: none;
}

.ButtonPWAClose {
  width: 28px;
  height: 28px;
  font-size: 0;
  color: transparent;
  cursor: pointer;
  padding: 0;
  border: 0;
  border-radius: 0;
  background: #fff;
  position: relative;

  &:before {
    content: "";
    width: 18px;
    height: 2px;
    border-radius: 6px;
    background-color: #b16db3;
    transform: rotate(-45deg);
    position: absolute;
    top: calc(50% - 1px);
    left: calc(50% - 9px);
  }

  &:after {
    content: "";
    width: 18px;
    height: 2px;
    border-radius: 6px;
    background-color: #b16db3;
    transform: rotate(45deg);
    position: absolute;
    top: calc(50% - 1px);
    left: calc(50% - 9px);
  }
}
