.asterisk{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    color: #6C757D;
}

.button{
    width: 100%;
    background: #b16db3;

    margin: 10% 0 15% 0px;

    box-shadow: 0px 7px 11px rgba(132, 69, 135, 0.3);
    border-radius: 6px;
    border: none;
}


.buttonContent {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    color: #ffffff;
}