.card {
    display: flex;
    margin: 5% 0px 5% 0px;
}

.infoCard{
    margin: 0 0 0 10px;
}

.title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    color: #6C757D;
    width: 120px;
    margin: 0 0 -20px 0px;
}

.info {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    
    color: #343A40;

    margin: 20px 0px 0px 0px;
}