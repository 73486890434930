.text {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: #6c757d;
}

.title {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-align: center;

  color: #343a40;
  margin: 5% 0 0 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 2%;
}

.button {
  width: 135px;
  background: #b16db3;

  margin: 5% 0 15% 0px;

  box-shadow: 0px 7px 11px rgba(132, 69, 135, 0.3);
  border-radius: 6px;
  border: none;
  align-self: center;
}

.buttonContent {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin: 11.5px;

  color: #ffffff;
}

.confirmButtonA {
  width: 110px;
  background: #ffffff;
  margin: 5% 10px 15% 10px;
  border: 2px solid #b16db3;
  box-sizing: border-box;
  box-shadow: 0px 7px 11px rgba(132, 69, 135, 0.3);
  border-radius: 6px;
}

.buttonContentA {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin: 11.5px;

  color: #b16db3;
}

.confirmButtonB {
  width: 120px;
  background: #b16db3;

  margin: 5% 10px 15% 10px;

  box-shadow: 0px 7px 11px rgba(132, 69, 135, 0.3);
  border-radius: 6px;
  border: none;
  align-self: center;
}

.buttonContentB {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  margin: 11.5px;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.confirmButtonContainer {
  align-self: center;
}

.img {
  width: 80px;
  align-self: center;
}
.row {
  display: flex;
  flex-direction: column;
}

.closeCycleBtn {
  display: flex;
  justify-content: center;
}

.buttonContentW {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: #b16db3;
  margin: 15px 25px 15px 25px;
}

.buttonContent {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin: 15px 25px 15px 25px;
}


.buttonSmall {
  background: #b16db3;
  margin: 0 18px 0 0;
  box-shadow: 0px 7px 11px rgba(132, 69, 135, 0.3);
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.buttonSmallW {
  background: #ffffff;
  margin: 0 18px 0 0;
  border: 2px solid #b16db3;
  box-sizing: border-box;
  box-shadow: 0px 7px 11px rgba(132, 69, 135, 0.3);
  border-radius: 6px;
  cursor: pointer;
}

.buttonBox {
  margin: 30px 0 30px 0;
  display: flex;
  justify-content: center;
}