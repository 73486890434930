.card {
  display: flex;
  align-items: center;
  width: auto;

  margin: 0 16px 64px 16px;

  background: #f9dcdc;
  border: 1px solid #d62424;
  border-radius: 6px;
}

.img {
  margin: 0 3% 0 3%;
}

.text {
  margin: 17px 12px 17px 0;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;

  color: #d62424;
}
