.container {
  margin-top: 15%;
  display: flex;
  flex-direction: column;
}

.img {
  width: 75px;
  margin: auto;
}
