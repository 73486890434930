.question {
  margin: 10% 0 5% 0;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: #343a40;
}

.questionBox {
  max-width: 576px;
  padding: 20px;
  margin: 10% auto 5%;
}

.container {
  flex-direction: column;
  display: flex;
  align-items: center;
}

.text {
  padding: 0 5% 0 5%;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: #6c757d;
}

.symptomContainer {
  display: flex;
  flex-wrap: wrap;
}
.newSymptomContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.newSymptomName {
  display: flex;
  margin: 3% 0 -4% 0;
  align-items: center;
}

.deleteIcon {
  margin: 20px 0 0 0;
}

.newSymptom {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #343a40;
}

.add {
  display: flex;
  margin: 2% 0 -0 2.7%;
}

.addT {
  margin-left: 5%;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #b16db3;
}

.button {
  width: 100%;
  background: #b16db3;

  margin: 25% 0 30% 0px;

  box-shadow: 0px 7px 11px rgba(132, 69, 135, 0.3);
  border-radius: 6px;
  border: none;
}

.buttonS {
  background: #b16db3;

  margin: 20% 0 20% 0px;

  box-shadow: 0px 7px 11px rgba(132, 69, 135, 0.3);
  border-radius: 6px;
  border: none;
}

.buttonContent {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: #ffffff;
  margin: 15px 50px 15px 50px;
}

.containerQualification {
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
  margin-top: 20%;
}

.dialogBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirmButtonA {
  width: 110px;
  background: #ffffff;
  margin: 5% 10px 15% 10px;
  border: 2px solid #b16db3;
  box-sizing: border-box;

  box-shadow: 0px 7px 11px rgba(132, 69, 135, 0.3);
  border-radius: 6px;
}

.buttonContentA {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin: 11.5px;

  color: #b16db3;
}

.confirmButtonB {
  width: 120px;
  background: #b16db3;

  margin: 5% 10px 15% 10px;

  box-shadow: 0px 7px 11px rgba(132, 69, 135, 0.3);
  border-radius: 6px;
  border: none;
  align-self: center;
}

.buttonContentB {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  margin: 11.5px;
  font-size: 16px;
  line-height: 20px;

  color: #ffffff;
}

.title {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #343a40;
  margin: 10% 0 15px 0;
}
