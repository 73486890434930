.header-1 {
  display: flex;
  flex-direction: column;

  padding: 43px 0px 20px 20px;
  margin-bottom: 1px;
}

.header-2 {
  display: flex;
  flex-direction: column;

  padding: 100px 0px 25px 20px;
  margin-bottom: 1px;
}

.header-bread {
  display: flex;
  flex-direction: column;
  padding: 10px 16px 15px 15px;
  margin-bottom: 1px;
}

.ialurilLogoWhite {
  width: 190px;
  margin: 0 auto 31px auto;
}

.ialurilLogoWhite2 {
  width: 190px;
  margin: 43px auto 51px auto;
  display: block;
}

.ialurilLogoWhite3 {
  width: 190px;
  margin: 43px auto 0 auto;
  display: block;
}

.greetings {
  display: flex;
  align-items: center;
}

.notification-bell {
  position: relative;
  cursor: pointer;
}

.notification-count {
  background-color: #85477e;
  color: white;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.alert-component {
  padding: 0px 10px 0 0;
  align-items: inherit;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  width: 75px;
}

.header-img {
  background-size: 30% !important;
}

@media (max-width: 950px) {
  .header-img {
    background-size: 40% !important;
  }
}

@media (max-width: 950px) {
  .header-img {
    background-size: 70% !important;
  }
}

@media (max-width: 570px) {
  .header-img {
    background-size: 100% !important;
  }
}