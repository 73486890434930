.card {
  margin: 20px auto 20px auto;
  max-width: 360px;
  box-shadow: $shadow-m;
  border-radius: $border-radius;
}

.card-title {
  background: map_get($primary, "purple");
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;

  img {
    padding: 7.5px 0 7.5px 20px;
  }

  p {
    font-weight: 900;
    font-size: 14px;
    line-height: 18px;

    color: map_get($primary, "white");
    padding: 7.5px 0 7.5px 4px;
  }
}

.card-title-img {
  justify-content: space-around;
  align-items: flex-end;
/*   padding: 0 15px 0 15px; */
  display: flex;
  max-width: 360px;
  height: 110px;
    margin: 0 auto 0 auto;

  .card-title-2 {
    display: flex;
    align-items: center;
  }

  img {
    padding: 7.5px 0 7.5px 20px;
  }

  p {
    font-weight: 900;
    font-size: 14px;
    line-height: 18px;

    color: map_get($primary, "white");
    padding: 7.5px 0 7.5px 4px;
  }
}

.card-content {
  display: flex;
  flex-direction: column;

  p {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;

    padding: 20px 20px 10px 20px;
    margin: 0;
    color: #343A40;
  }

  a {
    align-self: flex-end;
    padding: 0 26px 10px 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    text-decoration-line: underline;

    color: map_get($primary, "purple");
  }
}

.card-content-2 {
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  max-width: 360px;
  box-shadow: $shadow-m;
  margin: 0 auto 20px auto;

  p {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;

    padding: 20px;
    margin: 0;
    color: #343A40;
  }

  a {
    align-self: flex-end;
    padding: 0 26px 10px 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    text-decoration-line: underline;

    color: map_get($primary, "purple");
  }
}
