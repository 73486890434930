.homePage {
    justify-content: center;
  display: flex;
  height: 100vh;
}

.homeImg {
  width: 256px;
  height: 256px;
  align-self: center;
}
