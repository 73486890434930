.nameContainer {
  display: flex;
  margin: 0 0 20px 0;
  align-items: baseline;
  justify-content: space-between;
}

.innerContainer {
  max-width: 576px;
  width: 100%;
  margin: 0 auto;
}

.innerContainerRelevantInformation {
  max-width: 680px;
  width: 100%;
  margin: 0 auto;
}

.innerContainer3 {
  max-width: 576px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tableContainer {
  padding: 67px 16px 73px 16px;
  background: #f4f5f6;
}

.img {
  margin: 0 42px 0 0;
}

.line {
  width: 100%;
}

.greeting {
  margin: 67px 0 -20px 0;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: #6c757d;
}

.invima {
  display: flex;
  justify-content: center;
  font-family: Source Sans Pro;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;

  color: #6c757d;
}

.name {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;

  color: #343a40;
}

.grid {
  margin: 27px 0 27px 0;
  display: flex;
  align-items: center;
}

.infoBox {
  background: #ffffff;
  padding: 5%;

  box-shadow: 0px 7px 11px rgba(52, 58, 64, 0.3);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}

.title2 {
  margin: 0 0 20px -70px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-decoration: none;

  color: #343a40;
}

.title1 {
  margin: 39px 0 0 16px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;

  color: #343a40;
}
.modalTitle {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  margin: 30px 0 -15px 0;

  color: #343a40;
}

.recommendationContainer {
  background: #ead7ea;
  padding: 40px 0 100px 0;
}

.recommendationImg {
  background: rgba(52, 58, 64, 0.3);

  border-radius: 6px;
  max-width: 360px;
  margin: 0 auto 20px;

  width: inherit;
  height: inherit;
  cursor: pointer;
}

.infoImg {
  border-radius: 6px;
  margin: 0 5% 5% 0;
}

.imageText {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  position: absolute;

  margin: 165px 40px 0 0;
}

.recommendationBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.infoContainer {
  max-width: 100%;
  width: max-content;
  display: flex;
  padding: 20px 16px 10px 16px;
  box-sizing: border-box;
  overflow-x: auto;
}

.relevantInformationBox {
  margin: 0 7.5px 0 7.5px;
  width: 200px;
  height: 170px;
  border-radius: 6px;
}

.relevantInformationText {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin: -85px 0px 0 15px;
}

.subtitle {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #6c757d;
}

.buttonSmall {
  background: #b16db3;

  margin: 55px 0 65px 0;

  box-shadow: 0px 7px 11px rgba(132, 69, 135, 0.3);
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.buttonContent {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: #ffffff;
  margin: 15px 25px 15px 25px;
}
.modalBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonState {
  background: #ffffff;
  border: 2px solid #b16db3 !important;
  box-sizing: border-box;
  border-radius: 6px;
  margin: 0 0 30px 0;
  width: 100%;
}

.buttonStateContent {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}

.buttonStateText {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  color: #b16db3 !important;
  margin: 9px 0 9px 0;
}
