.footerBox {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  bottom: 0;
  width: 100%;
  height: 20%;
  padding: 20px;

  background: #fafafa;
  box-sizing: border-box;
}

.footerText {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  color: #b16db3;
}

.footerText2 {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin: 8px;
 width: 100px;
  color: #b16db3;
}

.link {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;
  text-decoration-line: underline;

  color: #b16db3;
  margin: 6.5px;

  cursor: pointer;
}

.img {
  margin: 5px 0 15px 0;
  width: 200px;
}
