.box {
  display: grid;
  margin: 0 0 30px 0;
}

.boxImg {
  width: 100%;
  max-width: 360px;
  height: 200px;
  margin: 0 auto;
  cursor: pointer;

  filter: drop-shadow(0px 7px 11px rgba(52, 58, 64, 0.3));
  border-radius: 6px 6px 0px 0px;
}

.boxTitle {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  z-index: 1;
  max-width: 360px;
  margin: 0 auto;

  background: #ffffff;

  box-shadow: 0px 7px 11px rgba(52, 58, 64, 0.3);
  border-radius: 0px 0px 6px 6px;
  cursor: pointer;
}

.title {
  margin: 20px 0 20px 13px;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  color: #343a40;
  cursor: pointer;
}

.icon {
  margin: 0 0 0 24px;
}
