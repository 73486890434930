.chipON { 
    display: flex;
    align-content: center;
    justify-content: center;
    border: 1px solid #B16DB3;
    width: fit-content;

    background: #B16DB3;
    border-radius: 16px;
    margin: 2% 1% 2% 1%;
}

.chipLabelON {
    color: #FFFFFF;
    margin: 4px 15px 4px 15px;

    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;

}

.chipOFF { 
    display: flex;
    align-content: center;
    justify-content: center;
    border: 1px solid #B16DB3;
    width: fit-content;

    background: #FFFFFF;
    border-radius: 16px;
    margin: 2% 1% 2% 1%;
}

.chipLabelOFF {
    color: #343A40;
    margin: 4px 15px 4px 15px;

    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;

}