.ladda-button{
    display: block;
    width: 100%;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    padding: 10px 16px;
    margin: 50px auto 40px;
    border-radius: 6px;
    border: 2px solid #b16db3;
    background-color: #b16db3;
    box-sizing: border-box;
    box-shadow: 0px 7px 11px rgba(132, 69, 135, 0.3);
}
.ladda-button:hover {
    border: 2px solid #b16db3;
    background-color: #b16db3;
}