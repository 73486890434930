.box-education-inner-container {
  max-width: 576px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  box-sizing: border-box;
}

.box-education-container {
  background: #f4f5f6;
  padding: 64px 0 114px 0;
  background: #dbe4ed;
}

.box-education-title {
  margin: 0 104px 0 16px;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;

  color: #343a40;
}

.box-education-img {
  width: 100%;
  height: 100%;
  margin: 5% 4% 8% 4%;

  border-radius: 12px;
}

.educationInfo-innerContainer1 {
  max-width: 360px;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 20px;
}

.educationInfo-title {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;

  color: #343a40;
}

.educationInfo-imgContainer {
  max-width: 360px;
  margin: 0 auto;
  padding: 16px;
  padding: 0 0 40px 0;
}

.educationInfo-img {
  width: 260px;
  border-radius: 20px;
  margin: 15px auto 15px;
}
