.letter {
  width: 80px;
  margin: 120px auto 0 auto;
  display: block;
}
ul {
  list-style-image: url("ialuril-logo-pwa.png");
}
li::marker {
  size: 10px;
}

.error-icon {
  width: 20px;
  padding-right: 7px;
}
