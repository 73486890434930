.complete-width {
  width: 100%;
}

.main {
  min-height: 100vh;
}

.container {
  max-width: 576px;
  margin: 0 auto 0 auto;
}

.inner-container {
  padding: 20px;
}

.error-inner-container {
  padding: 80px 20px 170px 20px;
}


.div-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.div-horizontal {
  display: flex;
  justify-content: center;
}

.div-horizontal-form {
  display: flex;
  margin: -10px 0 0 0;
};

.icon-container {
  display: flex;
  justify-content: space-around;
}

.container-horizontal-img {
  max-width: 100%;
  width: max-content;
  display: flex;
  padding: 0 0 15px 0;
  box-sizing: border-box;
  overflow-x: auto;
}

.modal-button-container{
  display: flex;
  justify-content: space-evenly;
  padding: 1px 0 20px 0;

}

.text-container-padding-margin{
  padding: 0 20px 0 20px;
}

.text-container-margin{
  margin: 20px;
}

.alert-pill{
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  border-radius: 10px;
  text-align: center;
  padding: 5px;
  border: 3px solid #ae5ca4;
}

.text-justify {
  text-align: justify;
}
.text-center {
  text-align: center;
}