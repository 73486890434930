// Colors

$primary: (
  "purple": #ae5ca4,
  "purple-2": #b16db3,
  "purple-3": #894381,
  "white": #ffffff,
  "lighten-1": #d9b3d2,
  "lighten-2": #e4c8e1,
  "lighten-3": #ebd6e8,
  "darken-1": #3949ab,
);

$secondary: (
  "green": #b2d28e,
  "purple": #cf63ce,
  "lighten-1": #f5e0f5,
);

$gray: (
  "darken-1": #343a40,
  "darken-2": #6c757d,
  "darken-3": #adb5bd,
  "lighten-1": #ced4da,
  "lighten-2": #e9ecef,
  "lighten-3": #f5f5f5,
);

$alert: (
  "red": #d62424,
  "orange": #ff7c17,
  "yellow": #ffd041,
  "green": #3aba8f,
  "blue": #16d6fa,
);

$light: (
  "lighten-1": #f9dcdc,
  "lighten-2": #ffe8d6,
  "lighten-3": #fff5d6,
  "lighten-4": #e0f5ee,
  "lighten-5": #d7f8fe,
);
