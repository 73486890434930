.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #894381;
}

.banner-img {
  padding: 0 8px 0 0;
}
