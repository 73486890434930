.recommendationBox1 {
  background-color: #f5ebf4;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.recommendationBox2 {
  background-color: #b06db4;
}

.recommendationBox3 {
  background-color: #f5ebf4;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.recommendationBox4 {
  background-color: #b06db4;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.recommendationBox5 {
  background-color: #f5ebf4;
}

.recommendationImgBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 55px 25px 35px 0;
}

.recommendationImgBox2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 55px 0 20px 25px;
}

.numberBox {
  background-color: #b06db4;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.numberBox2 {
  background-color: #ffffff;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.number {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 65px;
  color: #ffffff;
  margin: 20px 25px 0 25px;
}

.number2 {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 65px;
  color: #b06db4;
  margin: 20px 25px 0 25px;
}

.whiteB {
  color: #ffffff !important
}

.purpleB {
  color: #b06db4 !important
}

.whiteText {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  padding: 0 16px 25px 16px;
  color: #ffffff;
  margin: 0;
}

.colorText {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #353c42;
  padding: 0 16px 25px 16px;
  margin: 0;
}

.imgRecomendation1 {
  width: 200px;
}

.list {
  display: flex;
}

.b {
  font-size: 16px;
}

.wb {
  font-size: 16px;
  color: #ffffff;
}