.innerContainer {
  max-width: 576px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  box-sizing: border-box;
}

.title {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;

  color: #343a40;
}

.imgContainer {
  max-width: 360px;
  margin: 0 auto;
  padding: 16px;
  padding: 50px 0 40px 0;
}

.img {
  width: 260px;
  border-radius: 20px;
  margin: 15px auto 15px;
}
