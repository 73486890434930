.container {
  padding: 46px 16px 144px 16px;
  max-width: 576px;
  margin: 0 auto;
}

.box {
  margin: 0 0 60px 0;
}

.title {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;

  color: #343a40;
}

.gb{
  color: #6c757d
}

.wb{
  color: #ffffff;
}

.titleII {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;

  color: #b16db3;
}

.text {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #6c757d;
}

.textpurple {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: #b16db3;
}

.subTitle {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #ffffff;
}

.textContainer {
  padding: 102px 16px 144px 16px;
}
