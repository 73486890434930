.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 576px;
  width: 100%;
  margin: 0 auto;
  padding: 116px 0 0 0;
}

.logo {
  margin: 0 0 30% 0;
}

.text {
  padding: 5%;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: #6c757d;
}

.button {
  margin-top: 10%;
  width: 40%;
  background: #b16db3;

  box-shadow: 0px 7px 11px rgba(132, 69, 135, 0.3);
  border-radius: 6px;
  border: none;
}

.buttonContent {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  margin: 10% 0px 10% 0px;

  color: #ffffff;
}
.img {
  width: 200px;
}
