.educationBoxTitle {
  background-color: #834586;
  padding: 25px 20px 16px 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.educationBox1 {
  display: flex;
  align-items: center;
  background-color: #b06db4;
  padding: 25px 20px 16px 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.educationBox2 {
  display: flex;
  align-items: center;
  background-color: #b06db4;
  padding: 25px 20px 16px 20px;
}

.educationBox3 {
  display: flex;
  align-items: center;
  background-color: #b06db4;
  padding: 25px 20px 16px 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.educationCard1 {
  display: flex;
  flex-direction: column;
}

.number {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 65px;
  line-height: 0;
  color: #ffffff;
  margin: 0 10px 0 10px;
}

.whiteTextTitle {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 0;
  color: #ffffff;
  margin: 40px 0 25px 0;
}

.whiteText {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;

  color: #ffffff;
}

.colorText {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;

  color: #ffffff;
}

.imgContainer {
  background-color: #f5ebf4;
  display: flex;
  flex-direction: column;
  padding: 50px 0 50px 0;
}

.imgEducation {
  width: 260px;
  border-radius: 20px;
  margin: 10px auto 10px;
}

.imgBox1 {
  display: flex;
  background-color: #f5ebf5;
  padding: 25px 50px 25px 50px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.imgBox2 {
  display: flex;
  background-color: #f5ebf5;
  padding: 45px 0 40px 0;
}

.imgEducation2 {
  margin: 0 auto;
  width: 175px;
}
.imgEducation3 {
  margin: 0 auto;
  width: 300px;
}
