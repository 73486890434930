.container {
  max-width: 576px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 5%;
  box-sizing: border-box;
}

.containerBox {
  display: flex;
  flex-direction: column;
  padding: 5%;
  align-items: center;
}

.logo {
  width: 160px;
  margin: 30px 0 60px 0;
}

.menu {
  align-self: center;
}

.text {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  text-align: center;
  cursor: pointer;
  width: max-content;
  margin: 40px auto 0 auto;

  color: #b16db3;
}

.dialogText {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: #6c757d;
}

.button {
  width: 100%;
  background: #b16db3;

  margin: 30% 0 45% 0;

  box-shadow: 0px 7px 11px rgba(132, 69, 135, 0.3);
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.buttonSmall {
  background: #b16db3;

  margin: 0 18px 0 0;

  box-shadow: 0px 7px 11px rgba(132, 69, 135, 0.3);
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.buttonSmallW {
  background: #ffffff;

  margin: 0 18px 0 0;

  border: 2px solid #b16db3;
  box-sizing: border-box;

  box-shadow: 0px 7px 11px rgba(132, 69, 135, 0.3);
  border-radius: 6px;
  cursor: pointer;
}

.buttonContentW {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: #b16db3;
  margin: 15px 25px 15px 25px;
}

.buttonContent {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: #ffffff;
  margin: 15px 25px 15px 25px;
}

.buttonBox {
  margin: 30px 0 0 0;
  display: flex;
  justify-content: center;
}

.subtitle {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #6c757d;
}

.title {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height */

  margin: 15px 0 -10px 0;
  color: #343a40;
}

.box {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.img {
  width: 85px;
  margin: 10px 0 20px 0;
}

.subTitle {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #ffffff;
}
@media screen and (max-width: 500px) {
  .tabWrapper > span {
    font-size: 12px;
  }
}

.buttonState {
  background: #ffffff;
  border: 2px solid #b16db3 !important;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 9px 9px 9px 9px;
  width: 100%;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: #b16db3;
  margin: 9px 0 0 0;
}

.buttonStateGreen {
  background: #3aba8f;
  border: 2px solid #3aba8f;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 9px 9px 9px 9px;
  width: 100%;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: #ffffff !important;
  margin: 9px 0 0 0;
}

.buttonStateRed {
  background: #d62424;
  border: 2px solid #d62424;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 9px 9px 9px 9px;
  width: 100%;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: #ffffff !important;
  margin: 9px 0 0 0;
}

.buttonStateYellow {
  background: #ffd041;
  border: 2px solid #ffd041;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 9px 9px 9px 9px;
  width: 100%;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: #ffffff !important;
  margin: 9px 0 0 0;
}

.buttonStateText {
  margin: 0 0 0 0;
}

.buttonStateContent {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}
