.validation-list {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #343a40;
    padding: 0;
    list-style: none;
    margin: 15px auto 15px auto;
}

.validation-item-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 5px;

    &:last-child {
        margin: 0;
    }
}